/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap');

.logo {
  max-height: 100px;
  margin-bottom: 1em;
  color: #ffbb00;
}

body {
  background-color: #000;
  color: #fff;
  font-family: 'Lato', sans-serif;
  margin: 0 auto;
  max-width: 1024px;
  padding-bottom: 60px;
  padding-top: 260px
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 20px;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent */
  -moz-backdrop-filter: blur(10px); /* blur effect for Firefox */
  -webkit-backdrop-filter: blur(10px); /* blur effect for Safari */
  -moz-backdrop-filter: blur(10px); /* blur effect */
  backdrop-filter: blur(10px); /* fallback for Firefox */
  position: fixed;
  top: 0;
  width: 1000px;
  z-index: 1000;
  transition: all 0.3s ease;
}

header.scrolled {
  display: flex;
  justify-content: space-between;
}

header.scrolled h1 {
  flex-grow: 1;
  text-align: left;
}

header.scrolled nav ul li {
  padding: 5px;
}

header h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  margin-top: 0.3em;
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

header h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 4em;
  line-height: 0.8;
  text-transform: uppercase;
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  margin-top: 0em;
  margin-bottom:  0em;
  transition: all 0.7s ease;
}

section {
  padding: 20px;
}

section h2 {
  color: #ffbb00;
}
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  overflow: hidden;
}

nav ul li {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  animation: fadeIn 1s ease-in forwards;
  transition: background-color 0.5s ease-in-out;
  opacity: 0;
  width: 100%; 
  height: 100%;
  border-radius: 10px;
  transition: all 0.3s ease;
}


.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 100%; 
  border-radius: 10px;
}

nav ul li:hover {
  background-color: #0746a5; 
  border-radius: 10px;
}

nav ul li a:hover {
  color: #ddd;
}
nav ul li .active{
  color: #ffbb00;

}

a {
  color:#ffbb00;
}

p {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.contact p {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0.8;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensure items wrap if they don't fit in one line */
}

.contact div {
  flex: 1; /* Allow the text container to take available space */
}

.contact-picture {
  height: 200px;
  margin-left: 20px; /* Add some space between the text and the picture */
  flex-shrink: 0; /* Prevent the image from shrinking */
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%; /* makes the image round */
  object-fit: cover; /* ensures the image covers the whole area */
  border: 10px solid #fff; /* add a border */
}

footer {
  display: flex;
  justify-content: space-between;
  position: fixed; 
  bottom: 0; 
  width: 100%;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), #2a0136);
}

footer .nospace p {
  margin-left: 10px;
  margin-bottom: 10px;
}

footer  .social-icons {
  margin-right: 10px;
}



button {
  padding: 10px;
  border: #ffbb00 1px solid;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 1rem;
  color: #ffbb00;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  background-clip: padding-box; /* Ensure the border is displayed correctly */
}

button:hover {
  background: linear-gradient( #2a0136, #000 );
  border: #ffbb00 1px solid;
  background-clip: padding-box; /* Ensure the border is displayed correctly */
}
button:active {
  background: linear-gradient( #000, #2a0136 );
  border: #ffbb00 1px solid;
  background-clip: padding-box; /* Ensure the border is displayed correctly */
}


.boxPost {
  border: #ffbb00 1px solid;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}
.boxPost:hover {
  background: linear-gradient( #2a0136, #000);
  background-clip: padding-box;
}
.profile-picture {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%; /* makes the image round */
  width: 300px; /* adjust as needed */
  height: 300px; /* adjust as needed */
  object-fit: cover; /* ensures the image covers the whole area */
  border: 10px solid #fff; /* add a border */
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.tech-grid p {
  padding: 0;
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: color 0.5s ease-in-out;
  --x: 50%;
  --y: 50%;
}

.tech-item:hover {
  color: #ffbb00; 
  background: radial-gradient(circle at var(--x) var(--y), #2a0136, #000);
  background-clip: padding-box;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: #fff;
}

.social-icons a:hover {
  color: #ffbb00;
}

.nospace p {
  padding: 0;
  margin: 0;
}

.notification {
  text-align: center;
  padding: 20px;
  border: #ffbb00 1px solid;
  font-size: 2em;
  color: #ffbb00;
  border-radius: 10px;
}

.projectImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: #fff 5px solid;
}

.job-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #333333;
  border-radius: 10px;
  --x: 50%;
  --y: 50%;
}

.job-item:hover {
  background: linear-gradient( #2a0136, #000);
  background-clip: padding-box;
}

.job-item h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #ffbb00;
}

.job-item h4 {
  margin-bottom: 10px;
  color: #686868;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size:  1.2em;
}


.job-item p {
  margin-bottom: 10px;
  color: #bbbbbb;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid #bbbbbb;
  border-radius: 50%;
  border-top: 10px solid #ffbb00;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

  
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain; /* This will ensure the image aspect ratio is maintained */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes zoomIn {
  from {
    transform: scaley(0);
  }
  to {
    transform: scaley(1);
  }
}

.carousel-root {
  animation: zoomIn 0.5s;
  width: 100%;

}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  cursor: pointer;
}

.carousel-image {
  max-width: 100%;
  max-height: 77vh; /* Adjust the max height as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s;
}

.lightbox-close.fade-out {
  animation: fadeOut 0.5s;
}

.lightbox-content {
  display: flex;
  align-items: center;
}

.lightbox-content.fade-out {
  animation: fadeOut 0.5s;
}

.lightbox-image {
  max-width: 80%;
  max-height: 80%;
}

.lightbox-close,
.lightbox-prev,
.lightbox-next {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 1rem;
}
.lightbox-portfolio {
  position: absolute;
  bottom: 1rem;
  z-index: 999;
  padding: 10px;
  border: #ffbb00 1px solid;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: #ffbb00;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  left: 3em;
}
.lightbox-close {
  position: absolute;
  bottom: 1rem;
  z-index: 999;
  padding: 10px;
  border: #ffbb00 1px solid;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: #ffbb00;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  right: 3em;
}

@media (max-width: 960px) {
  header {
    width: 100vw;
  }
  
  header h1 {
    font-size: 3.5em;
  }

  header h2 {
    font-size: 1.3em;
  }
  nav ul {
    display: flex;
    width: calc(100vw - 80px);
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  nav ul li {
    text-align: center;
  }
  body {
    padding-top: 220px;
    padding-bottom: 60px;
  }

  .tech-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-left: 30px;
    height: 64px;
    justify-content: left;
  }

  .tech-item p {
    padding: 10px;
  }

  footer {
    left: 0;
    right: 0;
    width: auto;
    flex-direction: column;
  }

  .nospace {
    text-align: center;
  }

  .social-icons {
    display: none;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  header {
    width: 100vw;
  }
  header h1 {
    font-size: 2.2em;
  }

  header h2 {
    font-size: 1em;
  }
  nav ul {
    display: flex;
    width: calc(100vw - 40px);
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  nav ul li {
    text-align: center;
    height: 22px;
    width: 100%;
    border: 1px solid #fff;
    margin: 2px;
  }

  header.scrolled nav ul li {
    padding: 10px;
  }
  body {
    padding-top: 160px;
    padding-bottom: 60px;
  }

  button {
    padding: 10px;
    margin: 2px;
  }

  .tech-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  .tech-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-left: 15px;
    height: 64px;
    justify-content: left;
  }

  .tech-item p {
    padding: 10px;
    font-size: 0.8em;
  }

  footer {
    left: 0;
    right: 0;
    width: auto;
    flex-direction: column;
    font-size: 0.7em;
    height: 1em;
    padding-bottom: 10px;

  }

  .nospace {
    text-align: center;
  }

  .social-icons {
    display: none;
    justify-content: center;
  }

  .lightbox-content {
    width: 100%;
  }
  

}
